.no-student {
  text-align: center;
}

.student-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.student-body {
  max-width: 1200px;
  margin: 0 auto;
}

.add-student-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-student-form {
}

.student-item {
  display: block;
  border: 2px solid #eee;
  border-radius: .5rem;
  padding: 1rem;
  margin-bottom: 1rem;
}
