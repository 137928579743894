.students-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.new-student-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-student-form {
  margin: 0 auto;
  border-radius: .5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
}

.students-body {
  max-width: 1200px;
  margin: 0 auto;
}

.sensitive-data {
  width: 100%;
  background-color: #dc3545;
  color: white;
  font-weight: 800;
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
  padding: 10px;
  border-radius: 5px;
}