b, strong {
  font-weight: 600;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

table a{
  text-decoration: initial;
  color: #007bff;
}

.page-content {
  margin-top: 3rem;
}

.justify-children-end {
  display: flex;
  justify-content: flex-end;
}

.modal-content {
  margin-top: 10rem;
}

.react-bootstrap-table table > tbody > tr > td:nth-child(1) > input[type=radio] {
  margin-left: -.25rem;
}

.question-image-preview {
  max-width: 100%;
  max-height: 300px;
  display: block;
  margin: 0 auto;
}

td {
  word-break: break-all;
}

.newUser-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.newUser-form {
  min-width: 400px;
  border: 1px solid rgba(0,0,0, 0.1);
  padding: 1rem;
  border-radius: .5rem;
}

.newUser-title {
  text-align: center;
}
