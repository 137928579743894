.horizontal-arrangement{
  display: inline-block;
}

.flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;


}
