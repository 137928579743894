
.class-data.center {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
}

.class-data.center h1 {
    margin-bottom: 20px;
}

.wrapper {
    margin: 50px 200px;
    color: #424242;
}

.title {
    margin-bottom: 50px;
}

.title .class-name {
    margin-top: 30px;
    padding: 20px;
    background-color: white;
    border: 5px solid #01C8F3;
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.title .class-name h2 {
    margin-right: 20px;
}

.title .class-name h3 {
    color: #707070;
    font-size: 24px;
}

.question-set-wrapper {
    margin-top: 20px;
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    cursor: pointer;
}

.question-set-card {
    background-color: #eaeaea;
    border-radius: 10px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    align-content: center;
    margin: 10px 15px 20px 15px;
    padding: 10px 25px;
}

.question-set-card h3 {
    display: inline-block;
    width: 360px;
    font-size: 24px;
}

.question-set-card h4 {
    font-size: 18px;
    color: #0095B6;
}

.question-set-card .line {
    height: 40px;
    width: 2px;
    background: black;
    border: none;
    margin: 0 20px;
}

.question-set-separate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.question-set-see-more {
    color: #707070;
}

.big-number {
    margin-bottom: 30px;
    margin-left: 30px;
}

.big-number h1 {
    color: #0095B6;
}

.class-data-wrapper {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.graph-wrapper {
    width: 100%;
    margin-left: 200px;
}

.graph-wrapper .graph {
    margin-bottom: 50px;
}

@media(max-width: 1300px) {
    .wrapper {
        margin: 50px;
    }

    .graph-wrapper {
        margin-left: 100px;
    }
}

@media(max-width: 1000px) {
    .class-data-wrapper {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .graph-wrapper {
        margin-left: 0;
        margin-top: 50px;
    }

    .graph-wrapper canvas {
        margin: 0 auto;
    }

    .title .class-name {
        flex-direction: column;
        align-items: flex-start;
        align-content: flex-start;
    }

    .question-set-card {
        flex-direction: column;
    }

    .question-set-card .line {
        display: none;
    }

    .question-set-separate {
        align-items: flex-end;
        margin-top: 10px;
    }

    .question-set-card h3 {
        width: 100%;
    }
}

@media(max-width: 400px) {
    .wrapper {
        margin: 10px;
    }

    .graph-wrapper {
        overflow-x: scroll;
    }

    .graph {
        min-width: 400px;
    }

    .question-set-card h4:last-child {
        margin-bottom: 0;
    }
}