.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
}

.login-form {
  min-width: 400px;
  border: 1px solid rgba(0,0,0, 0.1);
  padding: 1rem;
  border-radius: .5rem;
}

.login-title {
  text-align: center;
}