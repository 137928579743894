.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  text-align: center;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.loading-page *, .loading * {
  flex: 1;
}