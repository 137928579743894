.controls-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.controls-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}